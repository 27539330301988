import { makeStyles } from '@mui/styles'
import { CSSFlexDirection } from 'lib/types/styles'

type Props = {
  orientation?: CSSFlexDirection
}

export default makeStyles(theme => ({
  container: ({ orientation }: Props) => ({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: orientation || 'row',
    justifyContent: 'space-between',
  }),
  checkboxRoot: {
    width: '45%',
    userSelect: 'none',
    whiteSpace: 'nowrap',
  },
  error: {
    color: theme.palette.error.dark,
  },
}))
