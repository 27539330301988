import React, { ReactNode } from 'react'
import IconButton from '@mui/material/IconButton'
import MUITooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/HelpOutline'
import useStyles from './styles'
import { TooltipPlacement } from 'lib/types/tooltip'

type Props = {
  title: NonNullable<ReactNode>
  iconSize?: {
    height: string
    width: string
  }
  placement?: TooltipPlacement
}

export const Tooltip = ({ title, iconSize, placement = 'bottom' }: Props) => {
  const classes = useStyles()

  return (
    <MUITooltip
      classes={{ tooltip: classes.popper }}
      title={title}
      placement={placement}
    >
      <IconButton component="span" aria-label="help" size="small">
        <HelpIcon fontSize="small" style={{ ...iconSize }} />
      </IconButton>
    </MUITooltip>
  )
}
